import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/FormInput';
import { countries } from 'constants/countries';
import { addDays, format } from 'date-fns';
import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { DateTimeFormats } from 'utils/parsers';
import { toastError } from 'utils/toast';
import * as yup from 'yup';

type Props = {
  onFinish: () => void;
  mode?: 'create' | 'read' | 'update';
  data?: any;
};

const PassportForm: React.FC<Props> = ({ onFinish, mode, data }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { uploadPassport } = useUserRequests();

  const schema = yup
    .object({
      document_number: yup.string().required('Passport number required'),
      first_name: yup.string().required('First Name required'),
      middle_name: yup.string(),
      last_name: yup.string().required('Last Name required'),
      nationality: yup.string().required('Nationality required'),
      date_of_birth: yup
        .date()
        .max(addDays(new Date(), -1), 'Birth date cannot be present or future')
        .required('Birth date is required'),
      date_of_issue: yup
        .date()
        .max(addDays(new Date(), -1), 'Issue date cannot be present or future')
        .required('Issue date is required'),
      date_of_expiration: yup
        .date()
        .min(addDays(new Date(), 1), 'Expiry date cannot be present or past')
        .required('Expiry date is required'),
      place_of_birth: yup
        .string()
        .oneOf(countries)
        .defined()
        .required('Birth place required'),
    })
    .required();

  if (data && data.date_of_birth && data.date_of_birth !== null) {
    data.date_of_birth = new Date(data.date_of_birth);
  }

  if (data && data.date_of_issue && data.date_of_issue !== null) {
    data.date_of_issue = new Date(data.date_of_issue);
  }

  if (data && data.date_of_expiration && data.date_of_expiration !== null) {
    data.date_of_expiration = new Date(data.date_of_expiration);
  }

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    ...(data ? { defaultValues: data } : {}),
  });

  const expiryDateValue = watch('date_of_expiration');
  const issueDateValue = watch('date_of_issue');
  const birthDateValue = watch('date_of_birth');

  const handleBirthDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('date_of_birth', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleExpiryDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('date_of_expiration', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleIssueDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('date_of_issue', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleContinue = useCallback(
    async (formData: any) => {
      setIsSubmitting(true);

      const body = {
        document_number: formData.document_number,
        first_name: formData.first_name,
        middle_name: formData.middle_name,
        last_name: formData.last_name,
        nationality: formData.nationality,
        date_of_birth: format(formData.date_of_birth, DateTimeFormats.FormDate),
        date_of_issue: format(formData.date_of_issue, DateTimeFormats.FormDate),
        date_of_expiration: format(
          formData.date_of_expiration,
          DateTimeFormats.FormDate
        ),
        place_of_birth: formData.place_of_birth,
      };

      const res = await uploadPassport(body);

      setIsSubmitting(false);
      if (res.status) {
        onFinish();
      } else {
        toastError();
      }
    },
    [uploadPassport]
  );

  return (
    <div>
      <h2 className="mb-7 text-xl">Passport Information</h2>

      <div className="flex-1 flex-col space-y-3">
        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="document_number"
          label="Passport Number"
          type="text"
          placeholder="Passport Document Number"
          errorMessage={errors.document_number?.message as string}
        />

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="first_name"
          label="Firs Name"
          type="text"
          placeholder="First Name"
          errorMessage={errors.first_name?.message as string}
        />

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="middle_name"
          label="Middle Name"
          type="text"
          placeholder="Middle Name"
        />

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="last_name"
          label="Last Name"
          type="text"
          placeholder="Last Name"
          errorMessage={errors.last_name?.message as string}
        />

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="nationality"
          label="Nationality"
          type="text"
          placeholder="Nationality"
          errorMessage={errors.nationality?.message as string}
        />

        <div>
          <label className="pl-1 text-sm text-secondary">Date of Birth</label>
          <ReactDatePicker
            disabled={mode === 'read'}
            selected={birthDateValue}
            onChange={handleBirthDatePickConfirm}
            dateFormatCalendar="MMMM"
            dateFormat={DateTimeFormats.DisplayDateShort}
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.date_of_birth && errors.date_of_birth?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.date_of_birth.message}
            </span>
          ) : null}
        </div>

        <div>
          <label className="pl-1 text-sm text-secondary">Date of Issue</label>
          <ReactDatePicker
            disabled={mode === 'read'}
            selected={issueDateValue}
            onChange={handleIssueDatePickConfirm}
            showYearDropdown
            dateFormatCalendar="MMMM"
            dateFormat={DateTimeFormats.DisplayDateShort}
            yearDropdownItemNumber={15}
            maxDate={new Date()}
            scrollableYearDropdown
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.date_of_issue && errors.date_of_issue?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.date_of_issue.message}
            </span>
          ) : null}
        </div>

        <div>
          <label className="pl-1 text-sm text-secondary">Date of Expiry</label>
          <ReactDatePicker
            disabled={mode === 'read'}
            selected={expiryDateValue}
            onChange={handleExpiryDatePickConfirm}
            showYearDropdown
            dateFormatCalendar="MMMM"
            dateFormat={DateTimeFormats.DisplayDateShort}
            yearDropdownItemNumber={15}
            minDate={new Date()}
            scrollableYearDropdown
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.date_of_expiration && errors.date_of_expiration?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.date_of_expiration.message}
            </span>
          ) : null}
        </div>

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="place_of_birth"
          label="Place of Birth"
          type="text"
          placeholder="Place of Birth"
          errorMessage={errors.place_of_birth?.message as string}
        />
      </div>

      {mode !== 'read' ? (
        <button
          className="btn-main mt-10 w-full"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(handleContinue)}
        >
          Continue
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PassportForm;
