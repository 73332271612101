export enum IDENTIFICATION_TYPES {
  PASSPORT = 'passport',
  DRIVERS_LICENSE = 'drivers_license',
}

export enum USER_DETAILS_TYPES {
  ACCOUNT = 'accountDetails',
  ADDRESS = 'addressDetails',
  IDENTIFICATION = 'identificationDetails',
  BANK = 'bankDetails',
}

export enum ESCROW_TYPES {
  ANYTIME = 'anytime',
  TIMED = 'timed',
}

export enum STORAGE_KEYS {
  SESSION_TOKEN = 'GoESCROW_accessToken',
}
