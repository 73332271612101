import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { FieldValues, useForm, useFormState } from 'react-hook-form';

import FormInput from 'components/FormInput';
import { validatePassword } from 'utils/validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiLock } from 'react-icons/fi';
import styles from 'utils/styles';
import { toastError, toastSuccess } from 'utils/toast';
import { useAuthRequests } from 'hooks/auth-hooks';

const ChangePassword = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { changePasswordAuth } = useAuthRequests();

  const schema = yup
    .object({
      oldPassword: yup.string().required('Please enter your old password'),
      newPassword: yup
        .string()
        .test(
          'is-valid-password',
          'Your password must contain uppercase and lowercase letters, at least one number, and at least one symbol.',
          validatePassword
        )
        .required('Password required'),
      confirmPassword: yup
        .string()
        .required('Please retype your password')
        .oneOf([yup.ref('newPassword')], 'Your passwords do not match.'),
    })
    .required();

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { isValid, errors } = useFormState({ control });

  const handleConfirm = useCallback(
    async (data: FieldValues) => {
      if (!data || !isValid) return;
      setIsSubmitting(true);
      const { data: resData, status } = await changePasswordAuth({
        current_password: data.oldPassword,
        new_password: data.newPassword,
        new_password_confirmation: data.confirmPassword,
      });
      setIsSubmitting(false);
      if (status) {
        reset();
        toastSuccess('Successfully changed password.');
      } else {
        toastError(resData);
      }
    },
    [isValid, isSubmitting]
  );

  return (
    <div className="flex h-full w-full flex-col items-center space-y-6 bg-white py-10 px-3">
      <h2 className="text-center text-xl font-semibold">Change Password</h2>

      <form
        className="flex flex-col gap-2 sm:w-1/2"
        onSubmit={handleSubmit(handleConfirm)}
      >
        <FormInput
          control={control}
          name="oldPassword"
          label="Current Password"
          type="password"
          autoComplete="password"
          disabled={isSubmitting}
          placeholder="Old Password"
          errorMessage={errors.oldPassword?.message as string}
          prefixComponent={
            <div
              className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
            >
              <FiLock className="text-secondary" />
            </div>
          }
        />

        <hr className="my-3" />

        <FormInput
          control={control}
          name="newPassword"
          label="New Password"
          type="password"
          autoComplete="password"
          disabled={isSubmitting}
          placeholder="New Password"
          errorMessage={errors.newPassword?.message as string}
          prefixComponent={
            <div
              className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
            >
              <FiLock className="text-secondary" />
            </div>
          }
        />
        <FormInput
          control={control}
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          autoComplete="password"
          disabled={isSubmitting}
          placeholder="Confirm Password"
          errorMessage={errors.confirmPassword?.message as string}
          prefixComponent={
            <div
              className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
            >
              <FiLock className="text-secondary" />
            </div>
          }
        />
        <button className="btn-main mt-10" disabled={isSubmitting || !isValid}>
          Update
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
