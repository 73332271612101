/* eslint-disable quotes */
/* eslint camelcase: 0 */

import React, { useCallback, useMemo, useState } from 'react';

import { Dialog } from '@headlessui/react';
import Modal from 'components/Modal';
import { useUserRequests } from 'hooks/user-hooks';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCheckCircle } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import DriverLicenseForm from './DriverLicenseForm';
import MedicareForm from './MedicareForm';
import PassportForm from './PassportForm';
import { twMerge } from 'tailwind-merge';

type Props = {
  refetch?: any;
  data?: any;
  readonly?: boolean;
  isUpdate?: boolean;
};

export const DocumentDetails: React.FC<Props> = ({
  data,
  refetch,
  isUpdate = false,
  readonly,
}) => {
  const [mode, setMode] = useState<'create' | 'read' | 'update'>('create');

  const [isModalOpen, setIsModalOpen] = useState<
    'license' | 'medicare' | 'passport' | undefined
  >();
  const isLicenseComplete =
    data && data.driver_license && data.driver_license.license_number;
  const isMedicareComplete =
    data && data.medicare && data.medicare.document_number;
  const isPassportComplete =
    data && data.passport && data.passport.document_number;

  // useEffect(() => {
  //   if (!isUpdate && checkSufficientIdentification(data)) {
  //     doLoginNavigations();
  //   }
  // }, [data, isUpdate]);

  const closeModal = useCallback(() => {
    setIsModalOpen(undefined);
  }, []);

  const handleForm = useCallback(
    (form: 'license' | 'medicare' | 'passport') => () => {
      switch (form) {
        case 'license':
          setMode(isLicenseComplete ? 'read' : 'create');
          break;
        case 'medicare':
          setMode(isMedicareComplete ? 'read' : 'create');
          break;
        case 'passport':
          setMode(isPassportComplete ? 'read' : 'create');
          break;
      }
      setIsModalOpen(form);
    },
    [isLicenseComplete, isMedicareComplete, isPassportComplete]
  );

  const handleFinish = useCallback(() => {
    if (refetch) refetch();
    setMode('read');
    closeModal();
  }, [refetch, closeModal]);

  const ModalContent = useMemo(() => {
    if (!isModalOpen) return <></>;
    switch (isModalOpen) {
      case 'license':
        return (
          <DriverLicenseForm
            onFinish={handleFinish}
            data={data?.driver_license}
            mode={mode}
          />
        );
      case 'medicare':
        return (
          <MedicareForm
            onFinish={handleFinish}
            data={data?.medicare}
            mode={mode}
          />
        );
      case 'passport':
        return (
          <PassportForm
            onFinish={handleFinish}
            data={data?.passport}
            mode={mode}
          />
        );
    }
  }, [isModalOpen, data, mode]);

  const EditButton = useMemo(() => {
    switch (mode) {
      case 'create':
        return <></>;
      case 'read':
        return (
          <button onClick={() => setMode('update')}>
            <h4 className="text-lg font-normal text-primary">Edit</h4>
          </button>
        );
      case 'update':
        return (
          <button onClick={() => setMode('read')}>
            <h4 className="text-lg font-normal text-primary">Cancel</h4>
          </button>
        );
    }
  }, [mode]);

  return (
    <div>
      <h3 className="text-2xl">Personal Identification Check</h3>
      <p className="mt-2 text-neutral-700">
        Please provide two Australian Government issued identification document
        references from this list:
      </p>
      <h3 className="mb-5 mt-9 text-lg text-primary">ID References</h3>

      <div className="flex w-full flex-col gap-3">
        <button
          className={twMerge(
            'flex flex-row items-center justify-between bg-white p-4 text-gray-400 shadow-lg ring-1 ring-neutral-100',
            isLicenseComplete && 'text-black'
          )}
          onClick={handleForm('license')}
        >
          <p>Driver&apos;s License</p>
          {isLicenseComplete ? (
            <FaCheckCircle className="text-success" />
          ) : (
            <FiPlus className="text-2xl text-gray-400" />
          )}
        </button>

        <button
          className={twMerge(
            'flex flex-row items-center justify-between bg-white p-4 text-gray-400 shadow-lg ring-1 ring-neutral-100',
            isMedicareComplete && 'text-black'
          )}
          onClick={handleForm('medicare')}
        >
          <p>Medicare</p>
          {isMedicareComplete ? (
            <FaCheckCircle className="text-success" />
          ) : (
            <FiPlus className="text-2xl text-gray-400" />
          )}
        </button>

        <button
          className={twMerge(
            'flex flex-row items-center justify-between bg-white p-4 text-gray-400 shadow-lg ring-1 ring-neutral-100',
            isPassportComplete && 'text-black'
          )}
          onClick={handleForm('passport')}
        >
          <p>Passport</p>
          {isPassportComplete ? (
            <FaCheckCircle className="text-success" />
          ) : (
            <FiPlus className="text-2xl text-gray-400" />
          )}
        </button>
      </div>
      <Modal isOpen={isModalOpen !== undefined} onClose={closeModal}>
        <Dialog.Title className="flex w-full flex-row items-center justify-between text-xl font-semibold text-secondary">
          Identification Documents
          {EditButton}
        </Dialog.Title>
        {ModalContent}
      </Modal>
    </div>
  );
};

export default DocumentDetails;
