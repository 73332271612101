import { useUserRequests } from 'hooks/user-hooks';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from './routes';
import { STORAGE_KEYS } from 'constants/enums';

interface RouteGuardProps {
  children: ReactNode;
}

export const RouteGuard = ({ children }: RouteGuardProps) => {
  const navigate = useNavigate();
  const { doLoginNavigations } = useUserRequests();

  useEffect(() => {
    guard();
  }, []);

  const guard = async () => {
    const token = localStorage.getItem(STORAGE_KEYS.SESSION_TOKEN);
    if (token) {
      doLoginNavigations(true);
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  return <>{children}</>;
};
