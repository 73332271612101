import TextInput from 'components/TextInput';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styles from 'utils/styles';

import logoHorizontal from 'assets/images/logo-withtext-horizontal.png';
import { useUserRequests } from 'hooks/user-hooks';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';
import add from 'date-fns/add';
import { Timer } from 'components/Timer';
import { useAuthRequests } from 'hooks/auth-hooks';
import { STORAGE_KEYS } from 'constants/enums';

const TwoFactor = () => {
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resendActiveTime, setResendActiveTime] = useState<Date>(
    add(new Date(), { minutes: 1 })
  );

  const [resendActive, setResendActive] = useState<boolean>(false);

  const { verifyEmail, promptVerifyEmail } = useAuthRequests();
  const { doLoginNavigations, getUserDetails } = useUserRequests();
  const navigate = useNavigate();

  useEffect(() => {
    guard();
  }, []);

  const guard = async () => {
    const token = localStorage.getItem(STORAGE_KEYS.SESSION_TOKEN);
    if (token) {
      const { data, status } = await getUserDetails();
      if (status && data.email_verified_at) {
        doLoginNavigations();
      }
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  const isVerifyDisabled = useMemo(() => {
    if (!code || code.length < 6 || isLoading) return true;
    return false;
  }, [code, isLoading]);

  const handleVerifyNow = useCallback(async () => {
    setIsLoading(true);
    const { status } = await verifyEmail({
      email_verification_code: code,
    });
    if (status) {
      setIsLoading(false);
      doLoginNavigations();
    } else {
      toastError();
      setIsLoading(false);
    }
  }, [code]);

  const handleResendConfirmationCode = useCallback(async () => {
    try {
      await promptVerifyEmail();
      setResendActive(false);
      setResendActiveTime(add(new Date(), { minutes: 5 }));
    } catch (e) {
      toastError();
      setResendActiveTime(add(new Date(), { seconds: 15 }));
      setResendActive(false);
    }
  }, [setResendActive, setResendActiveTime]);

  const Content = useMemo(() => {
    return (
      <>
        <div>
          <h1 className="text-lg font-semibold text-secondary sm:text-3xl">
            Verification
          </h1>
          <h2 className="mt-1 font-light">
            Enter 6-digit number that was sent to your Email Address
          </h2>
        </div>

        <TextInput
          value={code}
          onChange={(e) => setCode(e.currentTarget.value)}
          containerClassName="w-full"
          className="mt-4 py-2 text-center text-2xl font-medium tracking-widest"
          id="otp"
          name="otp"
          minLength={6}
          maxLength={6}
          required
          placeholder="••••••"
        />

        <div className="h-4" />

        <button
          onClick={handleVerifyNow}
          disabled={isVerifyDisabled}
          className="btn-main w-full self-center py-2 sm:self-start"
        >
          <div className="flex flex-row justify-center">
            <h2 className="text-lg font-semibold">Verify Now</h2>
            <BsFillArrowRightCircleFill className="ml-3 text-2xl text-white" />
          </div>
        </button>
        {resendActive ? (
          <button type="button" onClick={handleResendConfirmationCode}>
            <h5
              className={
                'flex-row items-center text-center font-semibold text-primary underline'
              }
            >
              Resend code
            </h5>
          </button>
        ) : (
          <Timer
            date={resendActiveTime}
            setComplete={() => {
              setResendActive(true);
            }}
          />
        )}
      </>
    );
  }, [
    handleVerifyNow,
    isVerifyDisabled,
    resendActive,
    resendActiveTime,
    handleResendConfirmationCode,
  ]);

  return (
    <div className="flex flex-1 items-center justify-center">
      <div
        className={`flex w-10/12 flex-col ${styles.flexCenter} mt-5 space-y-6 text-center sm:w-[45%]`}
      >
        <img
          src={logoHorizontal}
          alt="GoEscrow Logo"
          className="mt-10 mb-5 flex h-auto w-[50%] flex-1 self-center"
        />
        {Content}
      </div>
    </div>
  );
};

export default TwoFactor;
