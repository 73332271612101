/* eslint-disable camelcase */
import axiosInstance from 'api';
import axios from 'axios';
import { API_BASE_URL, API_ENDPOINTS } from 'constants/api';
import { STORAGE_KEYS, USER_DETAILS_TYPES } from 'constants/enums';
import { format } from 'date-fns';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { ROUTES } from 'routers/routes';
import { DealForm, InviteForm, NoteForm } from 'type/deals-dto';
import { DateTimeFormats } from 'utils/parsers';

export const useDealsRequests = () => {
  const navigate = useNavigate();

  const getDealFees = async () => {
    const { data, status } = await axiosInstance.get(API_ENDPOINTS.DEALS_FEES);
    return { data, status };
  };

  const getDeals = async (params = {}) => {
    const { data, status } = await axiosInstance.get(API_ENDPOINTS.DEALS, {
      params,
    });
    return { data, status };
  };

  const getDealDetails = async (id: string) => {
    const { data, status } = await axiosInstance.get(
      `${API_ENDPOINTS.DEALS}/${id}`
    );
    return { data, status };
  };

  const acceptDeal = async (id: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/accept`
    );
    return { data, status };
  };

  const denyCancelRequest = async (id: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/deny-cancel`
    );
    return { data, status };
  };

  const cancelDeal = async (id: string, two_factor_code: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/cancel`,
      {
        two_factor_code: two_factor_code || '',
      }
    );
    return { data, status };
  };

  const declineDeal = async (id: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/decline`
    );
    return { data, status };
  };

  const closeDeal = async (id: string, two_factor_code?: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/close`,
      {
        two_factor_code: two_factor_code || '',
      }
    );
    return { data, status };
  };

  const dealNavigationHelper = (deal: any, user: any, replace?: boolean) => {
    if (!deal) return;
    if (deal.transaction_type === 'send')
      sendDealNavigationHelper(deal, user, replace);
    else requestDealNavigationHelper(deal, user, replace);
  };

  const sendDealNavigationHelper = (
    deal: any,
    user: any,
    replace?: boolean
  ) => {
    let designatedRoute = '';

    if (deal.initiator === 'me') {
      // if (deal.payment_status === 'pending') {
      //   designatedRoute = ROUTES.TRANSFER_PAYMENT;
      // } else if (deal.payment_status === 'paid') {
      designatedRoute = ROUTES.TRANSACTION_DETAILS;
      // }
    } else {
      // request bank details
      if (!user.bank_account) {
        navigate(
          {
            pathname: ROUTES.VIEW_DETAILS,
            search: `?${createSearchParams({
              type: USER_DETAILS_TYPES.BANK,
              mode: 'edit',
            })}`,
          },
          { state: { deal } }
        );
        return;
      } else if (deal.status === 'pending') {
        designatedRoute = ROUTES.ACCEPT_DEAL;
      } else {
        designatedRoute = ROUTES.TRANSACTION_DETAILS;
      }
    }

    // navigation logic
    if (replace) {
      navigate(designatedRoute, { state: { dealId: deal.id } });
    }
    navigate(designatedRoute, { state: { dealId: deal.id } });
  };

  const requestDealNavigationHelper = (
    deal: any,
    user: any,
    replace?: boolean
  ) => {
    let designatedRoute = '';
    // designated route conditionals
    if (deal.initiator === 'me') {
      if (!user.bank_account) {
        navigate(
          {
            pathname: ROUTES.VIEW_DETAILS,
            search: `?${createSearchParams({
              type: USER_DETAILS_TYPES.BANK,
              mode: 'edit',
            })}`,
          },
          { state: { deal } }
        );
        return;
      } else designatedRoute = ROUTES.TRANSACTION_DETAILS;
    } else {
      switch (deal.status) {
        case 'pending':
          designatedRoute = ROUTES.ACCEPT_DEAL;
          break;
        default:
          designatedRoute = ROUTES.TRANSACTION_DETAILS;
          break;
        // case 'completed':
        // case 'cancelled':
        //   designatedRoute = ROUTES.TRANSACTION_DETAILS;
        //   break;
        // case 'accepted':
        //   // if (deal.payment_status === 'pending') {
        //   //   designatedRoute = ROUTES.TRANSFER_PAYMENT;
        //   // } else {
        //   designatedRoute = ROUTES.TRANSACTION_DETAILS;
        //   // }
        //   break;
      }
    }

    // navigation logic
    if (replace) {
      navigate(designatedRoute, { state: { dealId: deal.id } });
    }
    navigate(designatedRoute, { state: { dealId: deal.id } });
  };

  const createDeal = async (form: DealForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.DEALS,
      form
    );
    return { data, status };
  };

  const bypassPayment = async (id: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS}/${id}/paid`
    );
    return { data, status };
  };

  const sendInvite = async (form: InviteForm, id: string) => {
    const { data, status } = await axiosInstance.post(
      `${API_ENDPOINTS.DEALS}/${id}/invite`,
      form
    );
    return { data, status };
  };

  const getDealFromToken = async (token: string) => {
    const { data, status } = await axiosInstance.get(
      `${API_ENDPOINTS.DEALS_TOKEN}/${token}`
    );
    return { data, status };
  };

  const acceptDealFromToken = async (token: string) => {
    const { data, status } = await axiosInstance.put(
      `${API_ENDPOINTS.DEALS_TOKEN}/${token}/accept`
    );
    return { data, status };
  };

  const addDealNote = async (form: NoteForm, id: string) => {
    const { data, status } = await axiosInstance.post(
      `${API_ENDPOINTS.DEALS}/${id}/notes`,
      form
    );
    return { data, status };
  };

  const deleteDealNote = async (noteId: string, dealId: string) => {
    const { data, status } = await axiosInstance.delete(
      `${API_ENDPOINTS.DEALS}/${dealId}/notes/${noteId}`
    );
    return { data, status };
  };

  const payByPoli = async (id: string) => {
    const { data, status } = await axiosInstance.get(
      `${API_ENDPOINTS.DEALS}/${id}/pay/poli?redirectToWeb=1`
    );
    return { data, status };
  };

  const payByPayID = async (id: string) => {
    const { data, status } = await axiosInstance.get(
      `v2/${API_ENDPOINTS.DEALS}/${id}/azupay/payment-request`
    );
    return { data, status };
  };

  const exportTransactionsToCSV = async () => {
    const token = localStorage.getItem(STORAGE_KEYS.SESSION_TOKEN);
    const { data, status } = await axios.get(
      `${API_ENDPOINTS.EXPORT_CSV}?from=2023-01-01&to=${format(
        new Date(),
        DateTimeFormats.FormDate
      )}`,
      {
        headers: { Accept: 'text/csv', Authorization: `Bearer ${token}` },
        maxBodyLength: Infinity,
        baseURL: API_BASE_URL,
      }
    );
    return { data, status };
  };

  const lookupPayID = async (type: 'PHONE' | 'EMAIL', payId: string) => {
    const { data, status } = await axiosInstance.post(
      `${API_ENDPOINTS.AZUPAY}/check-pay-id`,
      {
        payId,
        type,
        isPayTo: true,
      }
    );
    return { data, status };
  };

  const lookupBSB = async (bsb: string) => {
    const { data, status } = await axiosInstance.post(
      `${API_ENDPOINTS.AZUPAY}/check-bsb`,
      {
        bsb,
        isPayTo: true,
      }
    );
    return { data, status };
  };

  const submitPayTo = async (
    dealId: string,
    payload:
      | { payId: string; payIdType: string }
      | { bsb: string; accountNumber: string }
  ) => {
    const { data, status } = await axiosInstance.post(
      `v2/deals/${dealId}/azupay/payto`,
      payload
    );
    return { data, status };
  };

  return {
    getDealFees,
    getDeals,
    getDealDetails,
    createDeal,
    acceptDeal,
    declineDeal,
    denyCancelRequest,
    cancelDeal,
    closeDeal,
    bypassPayment,
    sendInvite,
    getDealFromToken,
    acceptDealFromToken,
    dealNavigationHelper,
    addDealNote,
    deleteDealNote,
    payByPoli,
    payByPayID,
    exportTransactionsToCSV,
    lookupPayID,
    lookupBSB,
    submitPayTo,
  };
};
