/* eslint-disable camelcase */
import axiosInstance from 'api';
import { API_ENDPOINTS } from 'constants/api';
import {
  UserAddressForm,
  UserBankDetailsForm,
  UserDetailsForm,
  UserIdentificationForm,
} from 'type/user-dto';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';
import { useAuthRequests } from './auth-hooks';

export const useUserRequests = () => {
  const { signOut } = useAuthRequests();
  const navigate = useNavigate();

  const getUserDetails = async () => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.USER_DETAILS
    );
    return { data, status };
  };

  const updateUserDetails = async (form: UserDetailsForm) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.USER_BASIC_DETAILS,
      form
    );
    return { data, status };
  };

  const updateUserAddress = async (form: UserAddressForm) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.USER_ADDRESS,
      form
    );
    return { data, status };
  };

  const updateUserIdentification = async (form: UserIdentificationForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_IDENTITY,
      form
    );
    return { data, status };
  };

  const updateUserBankDetails = async (form: UserBankDetailsForm) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.USER_BANK_DETAILS,
      form
    );
    return { data, status };
  };

  const getUserPaymentDetails = async () => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.USER_PAYMENT
    );
    return { data, status };
  };

  const doLoginNavigations = async (skipNavigate?: boolean) => {
    getUserDetails()
      .then((userDetails) => {
        // if backend user data is invalid, navigate to login
        if (!userDetails.status) {
          throw userDetails.data;
        } else if (!userDetails.data.email_verified_at) {
          navigate(ROUTES.TWO_FACTOR);
          return;
        } else if (!userDetails.data.phone_verified_at) {
          navigate(ROUTES.TWO_FACTOR_MOBILE);
          return;
        }
        // } else if (
        //   !userDetails.data.birth_date ||
        //   !userDetails.data.address ||
        //   !checkSufficientIdentification(userDetails.data)
        // ) {
        //   navigate(ROUTES.VERIFICATION_PROMPT);

        //   // If everything is all set, navigate to home
        // } else if (
        //   (userDetails.data.birth_date,
        //   userDetails.data.address,
        //   checkSufficientIdentification(userDetails.data))
        // )
        if (skipNavigate) return;
        navigate(ROUTES.HOME);
      })
      .catch(async (error) => {
        toastError(error);
        await signOut();
      });
  };

  const lookupGoEscrowUser = async (goEscrowId: string) => {
    const { data, status } = await axiosInstance.get(
      `${API_ENDPOINTS.USER_LOOKUP}/${goEscrowId}`
    );
    return { data, status };
  };

  const inviteUser = async (form: any) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_INVITE,
      form
    );
    return { data, status };
  };

  const uploadDriverLicense = async (form: any) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.DRIVER_LICENSE,
      form
    );
    return { data, status };
  };

  const uploadMedicare = async (form: any) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.MEDICARE,
      form
    );
    return { data, status };
  };

  const uploadPassport = async (form: any) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.PASSPORT,
      form
    );
    return { data, status };
  };

  const checkEmailExists = async (email: string) => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.USER_EMAIL_EXISTS,
      {
        params: {
          email: email,
        },
      }
    );
    return { data, status };
  };

  const verifyUserBankDetails = async (verification_code?: string) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_VERIFY_BANK,
      verification_code
        ? {
            verification_code,
          }
        : {}
    );
    return { data, status };
  };

  return {
    checkEmailExists,
    getUserDetails,
    updateUserDetails,
    updateUserAddress,
    updateUserIdentification,
    updateUserBankDetails,
    verifyUserBankDetails,
    getUserPaymentDetails,
    doLoginNavigations,
    lookupGoEscrowUser,
    inviteUser,
    uploadDriverLicense,
    uploadMedicare,
    uploadPassport,
  };
};
