/* eslint-disable camelcase */
import axiosInstance from 'api';
import { API_ENDPOINTS } from 'constants/api';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';
import {
  AuthChangeEmailForm,
  AuthChangeMobileForm,
  AuthChangePasswordAuthenticatedForm,
  AuthChangePasswordForm,
  AuthForgotPasswordForm,
  AuthLoginForm,
  AuthRegistrationForm,
  AuthVerifyEmailForm,
  AuthVerifyMobileForm,
} from 'type/auth-dto';

export const useAuthRequests = () => {
  const navigate = useNavigate();

  const signUp = async (form: AuthRegistrationForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.REGISTRATION,
      form
    );

    return { data, status };
  };

  const verifyEmail = async (form: AuthVerifyEmailForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.VERIFY_EMAIL,
      form
    );
    return { data, status };
  };
  const promptVerifyEmail = async () => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.PROMPT_EMAIL
    );
    return { data, status };
  };

  const promptVerifyMobile = async () => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.PROMPT_MOBILE
    );
    return { data, status };
  };

  const verifyMobile = async (form: AuthVerifyMobileForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.VERIFY_MOBILE,
      form
    );
    return { data, status };
  };

  const changeEmail = async (form: AuthChangeEmailForm) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.CHANGE_EMAIL,
      form
    );

    return { data, status };
  };

  const changeMobile = async (form: AuthChangeMobileForm) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.CHANGE_MOBILE,
      form
    );

    return { data, status };
  };

  const signIn = async (form: AuthLoginForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.LOGIN,
      form
    );
    return { data, status };
  };

  const forgotPassword = async (form: AuthForgotPasswordForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.FORGOT_PASSWORD,
      form
    );
    return { data, status };
  };

  const changePassword = async (form: AuthChangePasswordForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.CHANGE_PASSWORD,
      form
    );
    return { data, status };
  };

  const changePasswordAuth = async (
    form: AuthChangePasswordAuthenticatedForm
  ) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.CHANGE_PASSWORD_AUTH,
      form
    );
    return { data, status };
  };

  const signOut = async () => {
    localStorage.clear();
    navigate(ROUTES.LOGIN);
  };

  return {
    signUp,
    verifyEmail,
    promptVerifyEmail,
    signIn,
    forgotPassword,
    changePassword,
    changePasswordAuth,
    promptVerifyMobile,
    verifyMobile,
    changeEmail,
    changeMobile,
    signOut,
  };
};
