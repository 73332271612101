import axios, { AxiosInstance } from 'axios';
import { API_BASE_URL } from 'constants/api';
import { STORAGE_KEYS } from 'constants/enums';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

// Interceptors
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(STORAGE_KEYS.SESSION_TOKEN);
    if (config.headers && token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    config.baseURL = API_BASE_URL;
    return config;
  },
  async (error) => {
    console.log('[AXIOS] Error in request: ', error);
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    // Do something with response data
    return {
      ...response,
      data: response.data.data,
      status: response.status,
    };
  },
  (error) => {
    console.log('[AXIOS] Error in response: ', error);
    // Do something with request error
    return {
      data: error.response.data.message,
      status: false,
    };
  }
);

export default axiosInstance;
