import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm, useFormState } from 'react-hook-form';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { FiEye, FiEyeOff, FiLock, FiMail } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import logofull from 'assets/images/logo-full.svg';
import FormInput from 'components/FormInput';
import { useUserRequests } from 'hooks/user-hooks';
import { ROUTES } from 'routers/routes';
import styles from 'utils/styles';
import { toastError } from 'utils/toast';
import { useAuthRequests } from 'hooks/auth-hooks';
import { STORAGE_KEYS } from 'constants/enums';
import { AuthLoginForm } from 'type/auth-dto';

const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { doLoginNavigations } = useUserRequests();
  const { signIn } = useAuthRequests();

  useEffect(() => {
    guard();
  }, []);

  const schema = yup
    .object({
      email: yup.string().email('Invalid email').required('Email is required'),
      password: yup.string().min(8).required('Password is required'),
    })
    .required();
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { isValid, errors } = useFormState({ control });

  const handleLogin = useCallback(
    async (data: FieldValues) => {
      if (!data || !isValid) return;
      setIsLoading(true);

      const { data: resData, status } = await signIn(data as AuthLoginForm);
      setIsLoading(false);
      if (status) {
        localStorage.setItem(STORAGE_KEYS.SESSION_TOKEN, resData.token);
        doLoginNavigations();
      } else {
        toastError(resData || 'Login failed. Please try again.');
      }
    },
    [isValid, signIn, navigate]
  );

  const guard = async () => {
    const token = localStorage.getItem(STORAGE_KEYS.SESSION_TOKEN);
    if (token) {
      doLoginNavigations();
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <div className="flex flex-1 items-center justify-center">
      <div
        className={`flex w-10/12 flex-col ${styles.flexCenter} mt-5 space-y-6 text-center sm:w-[45%]`}
      >
        <img
          src={logofull}
          alt="GoEscrow Logo"
          className="my-10 flex h-[40%] w-[40%] flex-1 self-center"
        />
        <div>
          <h3 className="sm: text-lg font-semibold text-secondary">Sign In</h3>
          <p className="font-light">Enter Your Login Credentials</p>
        </div>

        <form className="w-full" onSubmit={handleSubmit(handleLogin)}>
          <FormInput
            control={control}
            name="email"
            type="email"
            autoComplete="email"
            required
            placeholder="Email address"
            prefixComponent={
              <div
                className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
              >
                <FiMail className="text-secondary" />
              </div>
            }
          />
          <FormInput
            control={control}
            name="password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="password"
            required
            placeholder="Password"
            containerClassName="mt-3"
            prefixComponent={
              <div
                className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
              >
                <FiLock className="text-secondary" />
              </div>
            }
            suffixComponent={
              <button
                type="button"
                className={`${styles.flexCenter} pointer-events-auto`}
                onClick={toggleShowPassword}
                tabIndex={-1}
              >
                {showPassword ? (
                  <FiEye className="mr-2 text-primary" />
                ) : (
                  <FiEyeOff className="mr-2 text-gray-500" />
                )}
              </button>
            }
          />
          <button
            className="btn-main mt-8 w-full self-center py-2"
            disabled={isLoading}
          >
            <div className="flex flex-row items-center justify-center">
              <h2 className="text-lg font-semibold">Sign In</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-2xl text-white" />
            </div>
          </button>
        </form>

        <div className="space-y-3">
          <p className="font-light">
            Don&apos;t have an account yet?{' '}
            <span className="font-medium text-primary">
              <Link to={ROUTES.REGISTER}>Register now</Link>
            </span>
          </p>

          <p className="font-medium text-primary">
            <Link to={ROUTES.FORGOT_PASSWORD}>Forgot Password</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
