import { yupResolver } from '@hookform/resolvers/yup';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { FiEye, FiEyeOff, FiLock, FiMail } from 'react-icons/fi';
import { CgPassword } from 'react-icons/cg';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import logofull from 'assets/images/logo-full.svg';
import FormInput from 'components/FormInput';
import { useUserRequests } from 'hooks/user-hooks';
import { ROUTES } from 'routers/routes';
import styles from 'utils/styles';
import { toastError, toastSuccess } from 'utils/toast';
import { validatePassword } from 'utils/validations';
import TextInput from 'components/TextInput';
import { useAuthRequests } from 'hooks/auth-hooks';
import { STORAGE_KEYS } from 'constants/enums';

enum STEPS {
  EMAIL = 'email',
  CHANGE_PASSWORD = 'changePassword',
}

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [step, setStep] = useState<STEPS>(STEPS.EMAIL);
  const [emailText, setEmailText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { doLoginNavigations } = useUserRequests();
  const { forgotPassword, changePassword } = useAuthRequests();

  useEffect(() => {
    guard();
  }, []);

  const schema = yup
    .object({
      code: yup.string().max(6).required('Code required'),
      password: yup
        .string()
        .test('is-valid-password', 'Invalid password', validatePassword)
        .required('Password required'),
    })
    .required();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { isValid, errors } = useFormState({ control });

  const handleForgot = useCallback(async () => {
    setIsLoading(true);
    // Validate
    const emailSchema = yup.string().email();
    try {
      emailSchema.validateSync(emailText);
      const { data, status } = await forgotPassword({ email: emailText });
      if (status) {
        toastSuccess('A code was sent to your email.');
        setStep(STEPS.CHANGE_PASSWORD);
      } else {
        toastError(data);
      }
    } catch (e) {
      toastError('Invalid email');
    } finally {
      setIsLoading(false);
    }
  }, [emailText]);

  const handleForgotConfirm = useCallback(
    async (data: any) => {
      if (!data || !isValid) return;
      setIsLoading(true);

      const { data: resData, status } = await changePassword({
        email: emailText,
        password_reset_verification_code: data.code,
        password: data.password,
      });
      setIsLoading(false);
      if (status) {
        toastSuccess('Password reset successfully');
        navigate(ROUTES.LOGIN);
      } else {
        toastError(resData);
      }
    },
    [isValid, emailText]
  );

  const handleLogin = useCallback(() => {
    navigate(ROUTES.LOGIN);
  }, []);

  const guard = async () => {
    const token = localStorage.getItem(STORAGE_KEYS.SESSION_TOKEN);
    if (token) {
      doLoginNavigations();
    }
  };

  const handleChangeEmailText = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setEmailText(e.currentTarget.value);
    },
    []
  );

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const Content = useMemo(() => {
    if (step === STEPS.EMAIL) {
      return (
        <div className="w-full space-y-6">
          <div>
            <h3 className="font-semibold text-secondary sm:text-lg">
              Reset Password
            </h3>
            <p className="font-light">Enter Your Email Address</p>
          </div>

          <div className="w-full">
            <TextInput
              name="email"
              type="email"
              autoComplete="email"
              required
              placeholder="Email address"
              value={emailText}
              onChange={handleChangeEmailText}
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <FiMail className="text-secondary" />
                </div>
              }
            />
          </div>
          <button
            className="btn-main mt-8 w-full self-center py-2"
            disabled={isLoading}
            onClick={handleForgot}
          >
            <div className="flex flex-row items-center justify-center">
              <h2 className="text-lg font-semibold">Continue</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-2xl text-white" />
            </div>
          </button>
        </div>
      );
    } else {
      return (
        <div className="w-full space-y-6">
          <div>
            <h3 className="font-semibold text-secondary sm:text-lg">
              Reset Password
            </h3>
            <p className="font-light">
              Enter the code sent to your email, along with your new password.
            </p>
          </div>

          <form className="w-full" onSubmit={handleSubmit(handleForgotConfirm)}>
            <TextInput
              name="email"
              type="email"
              autoComplete="email"
              required
              placeholder="Email address"
              value={emailText}
              disabled
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <FiMail className="text-secondary" />
                </div>
              }
            />
            <FormInput
              control={control}
              name="code"
              required
              placeholder="Code"
              containerClassName="mt-3"
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <CgPassword className="text-secondary" />
                </div>
              }
            />
            <FormInput
              control={control}
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="password"
              required
              placeholder="New Password"
              containerClassName="mt-3"
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <FiLock className="text-secondary" />
                </div>
              }
              suffixComponent={
                <button
                  type="button"
                  className={`${styles.flexCenter} pointer-events-auto`}
                  onClick={toggleShowPassword}
                  tabIndex={-1}
                >
                  {showPassword ? (
                    <FiEye className="mr-2 text-primary" />
                  ) : (
                    <FiEyeOff className="mr-2 text-gray-500" />
                  )}
                </button>
              }
            />
            <button
              className="btn-main mt-8 w-full self-center py-2"
              disabled={isLoading || !isValid}
            >
              <div className="flex flex-row items-center justify-center">
                <h2 className="text-lg font-semibold">Continue</h2>
                <BsFillArrowRightCircleFill className="ml-3 text-2xl text-white" />
              </div>
            </button>
          </form>
        </div>
      );
    }
  }, [
    step,
    control,
    isLoading,
    emailText,
    handleChangeEmailText,
    showPassword,
    toggleShowPassword,
    handleSubmit,
    handleLogin,
    isValid,
  ]);

  return (
    <div className="flex flex-1 items-center justify-center">
      <div
        className={`flex w-10/12 flex-col ${styles.flexCenter} mt-5 space-y-6 text-center sm:w-[45%]`}
      >
        <img
          src={logofull}
          alt="GoEscrow Logo"
          className="my-10 flex h-[40%] w-[40%] flex-1 self-center"
        />
        {Content}
        <p className="font-medium text-primary">
          <Link to={ROUTES.LOGIN}>Back to Login</Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
