import { Dialog } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { TbClockExclamation } from 'react-icons/tb';
import * as yup from 'yup';
import { debounce } from 'lodash';

import Modal from 'components/Modal';
import PayIDLogo from 'assets/images/pay-id-logo.svg';
import PayTOLogo from 'assets/images/payto-logo.svg';
import Spinner from 'assets/icons/spinner.svg';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { Dropdown } from 'components/Dropdown';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { toastError } from 'utils/toast';
import add from 'date-fns/add';
import { Timer } from 'components/Timer';
import { MdTimer } from 'react-icons/md';

enum PaymentMethods {
  PAYTO = 'payto',
  PAYID = 'payid',
  EFT = 'eft',
}

const TransferPayment = () => {
  const { getUserPaymentDetails } = useUserRequests();
  const location = useLocation();
  const { getUserDetails } = useUserRequests();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    PaymentMethods.PAYTO
  );
  const [payToMode, setPayToMode] = useState<'payid' | 'bsb'>('payid');
  const [payToType, setPayToType] = useState<'mobile' | 'email'>('mobile');
  const [payToPayID, setPayToPayID] = useState<string>('');
  const [payToError, setPayToError] = useState<string>('');
  const [payToBSB, setPayToBSB] = useState<{
    bsb: string;
    accountNumber: string;
  }>({
    bsb: '',
    accountNumber: '',
  });
  const [isPayToValid, setIsPayToValid] = useState<boolean>(false);

  const [showBsbAccountModal, setShowBsbAccountModal] =
    useState<boolean>(false);
  const [showPayIdModal, setShowPayIdModal] = useState<boolean>(false);
  const [showPayToPending, setShowPayToPending] = useState<boolean>(false);

  const [payTOActiveTime, setPayTOActiveTime] = useState<Date>(
    add(new Date(), { minutes: 10 })
  );

  const [isFetchingPayIDRequest, setIsFetchingPayIDRequest] =
    useState<boolean>(false);

  const [isPayIDLookupLoading, setIsPayIDLookupLoading] =
    useState<boolean>(false);

  // TODO: Temporary workaround for resetting location state issue
  const [dealId, setDealId] = useState<string>(location.state.dealId);
  const { data: userData } = useQuery(['userDetails'], getUserDetails);

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const {
    getDealDetails,
    dealNavigationHelper,
    lookupBSB,
    lookupPayID,
    submitPayTo,
    bypassPayment,
  } = useDealsRequests();

  const { data: paymentData } = useQuery(
    ['userPaymentDetails'],
    getUserPaymentDetails
  );

  const { data: dealData, refetch } = useQuery(['dealDetails', dealId], () =>
    getDealDetails(dealId)
  );

  const dealDetails = useMemo(
    () => (dealData && dealData.status ? dealData.data : {}),
    [dealData]
  );

  useEffect(() => {
    if (
      !['pending', 'pending-pay-to', 'partial'].includes(
        dealDetails?.payment_status
      )
    ) {
      setShowPayToPending(false);
      dealNavigationHelper(dealDetails, userDetails, true);
    }
  }, [dealDetails, userDetails]);

  useEffect(() => {
    setPayToPayID('');
    setPayToBSB({
      bsb: '',
      accountNumber: '',
    });
    setIsPayToValid(false);
  }, [payToType, payToMode, paymentMethod]);

  const handleBypassPay = useCallback(async () => {
    const res = await bypassPayment(dealId);
    if (res.status) {
      refetch();
    } else {
      toastError();
    }
  }, [dealId]);

  const onSelectOption = useCallback((value: string) => {
    setPayToType(value.toLowerCase() as 'mobile' | 'email');
  }, []);

  const handleLookup = useCallback(
    async (field: 'payid' | 'bsb', value: string) => {
      // offline validations
      setIsPayIDLookupLoading(false);
      if (!value || value.length < 6) {
        setIsPayIDLookupLoading(false);
        setIsPayToValid(false);
        setPayToError('');
        return;
      }
      if (field === 'payid') {
        if (payToType === 'email') {
          const emailSchema = yup.string().email();
          try {
            emailSchema.validateSync(value);
          } catch (e) {
            setIsPayIDLookupLoading(false);
            setIsPayToValid(false);
            setPayToError('');
            return;
          }
        } else {
          const phoneScheme = yup
            .string()
            .min(4)
            .max(15)
            .matches(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/g);
          try {
            phoneScheme.validateSync(value);
          } catch (e) {
            setIsPayIDLookupLoading(false);
            setIsPayToValid(false);
            setPayToError('');
            return;
          }
        }
      }

      // API Call
      if (field === 'payid') {
        setIsPayIDLookupLoading(true);
        const res = await lookupPayID(
          payToType.toUpperCase() === 'MOBILE' ? 'PHONE' : 'EMAIL' || '',
          value
        );
        setIsPayIDLookupLoading(false);
        if (res && res.status) {
          setIsPayToValid(true);
          setPayToError('');
        } else if (res && !res.status) {
          setPayToError(res.data);
        }
      } else {
        setIsPayIDLookupLoading(true);
        const res = await lookupBSB(value);
        setIsPayIDLookupLoading(false);
        if (res && res.status) {
          setIsPayToValid(true);
          setPayToError('');
        } else if (res && !res.status) {
          setPayToError(res.data);
        }
      }
    },
    [payToType]
  );

  const handleBSBInputs = (
    field: 'payid' | 'bsb' | 'accountNumber',
    value: string
  ) => {
    switch (field) {
      case 'payid': {
        setPayToPayID(value);
        debouncedTypeahead(field, value);
        break;
      }
      case 'bsb': {
        setPayToBSB({ ...payToBSB, bsb: value });
        debouncedTypeahead(field, value);
        break;
      }
      case 'accountNumber': {
        setPayToBSB({ ...payToBSB, accountNumber: value });
        break;
      }
    }
  };

  const debouncedTypeahead = useMemo(() => {
    return debounce(handleLookup, 500);
  }, [payToType]);

  const isContinueDisabled = useMemo(
    () =>
      (paymentMethod === PaymentMethods.PAYTO &&
        (!isPayToValid || isPayIDLookupLoading)) ||
      isFetchingPayIDRequest,
    [paymentMethod, isPayToValid, isPayIDLookupLoading]
  );

  const Disclaimer = useMemo(() => {
    if (isPayToValid) {
      return (
        <p className="mt-2 text-sm font-semibold">
          <FaCheckCircle className=" mr-2 inline-block text-lg text-success" />
          {payToMode === 'payid' ? 'PayID supported' : 'Bank supports PayTO'}
        </p>
      );
    }
    if (!isPayToValid && payToError) {
      return (
        <p className="mt-2 text-sm font-semibold">
          <FaTimesCircle className=" mr-2 inline-block text-lg text-highlight-red" />
          {payToError}
        </p>
      );
    }
  }, [isPayToValid, payToError]);

  const handleContinue = useCallback(async () => {
    switch (paymentMethod) {
      case PaymentMethods.EFT: {
        setShowBsbAccountModal(true);
        break;
      }
      case PaymentMethods.PAYID: {
        setShowPayIdModal(true);
        break;
      }
      case PaymentMethods.PAYTO: {
        setIsFetchingPayIDRequest(true);
        const payload =
          payToMode === 'payid'
            ? {
                payId: payToPayID,
                payIdType:
                  payToType.toUpperCase() === 'MOBILE'
                    ? 'PHONE'
                    : 'EMAIL' || '',
              }
            : {
                bsb: payToBSB.bsb || '',
                accountNumber: payToBSB.accountNumber || '',
              };
        const res = await submitPayTo(dealId, payload);
        if (res && res.status) {
          setIsFetchingPayIDRequest(false);
          setShowPayToPending(true);
          setPayTOActiveTime(add(new Date(), { minutes: 10 }));
        }
        break;
      }
    }
  }, [paymentMethod, dealId, payToBSB, payToPayID, payToType, payToMode]);

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-start gap-4 bg-white">
      <h2 className="mt-5 text-center text-xl text-primary">
        Send Funds into Escrow
      </h2>

      <button
        className="flex w-10/12 flex-col rounded-md bg-neutral-100 px-4 py-3 shadow-md"
        onClick={() => setPaymentMethod(PaymentMethods.PAYTO)}
      >
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <div
              className={twMerge(
                'mr-3 flex h-3.5 w-3.5 items-center justify-center rounded-full bg-white ring-2 ring-gray-300',
                paymentMethod === PaymentMethods.PAYTO &&
                  'border border-white bg-primary ring-primary'
              )}
            />

            <h2 className="text-lg font-semibold">PayTo</h2>
          </div>
          <img src={PayTOLogo} alt="PayTo Logo" className="h-[30px]" />
        </div>
        {paymentMethod === PaymentMethods.PAYTO && (
          <div className="flex w-full flex-col items-start pl-7 pr-1">
            <p>View and control from your online banking</p>
            {payToMode === 'payid' ? (
              <div className="mt-3 flex w-full flex-row rounded-xl border border-gray-300 bg-white align-middle">
                <div className="flex w-32 grow-0">
                  <Dropdown
                    className="rounded-xl"
                    items={['Mobile', 'Email']}
                    selected={
                      payToType.charAt(0).toUpperCase() + payToType.slice(1)
                    }
                    onChange={onSelectOption}
                    disabled={
                      paymentMethod !== PaymentMethods.PAYTO ||
                      isFetchingPayIDRequest
                    }
                  />
                </div>
                <input
                  className="flex flex-1 rounded-xl px-2 focus-visible:outline-none"
                  type={payToType === 'mobile' ? 'tel' : 'email'}
                  placeholder={
                    payToType === 'mobile'
                      ? '+99 9 999 9999'
                      : 'example@email.com'
                  }
                  disabled={
                    paymentMethod !== PaymentMethods.PAYTO ||
                    isFetchingPayIDRequest
                  }
                  value={payToPayID}
                  onChange={(e) => {
                    handleBSBInputs('payid', e.target.value);
                  }}
                />
              </div>
            ) : (
              <div className="mt-3 flex w-full flex-row gap-3">
                <div className="flex flex-col items-start">
                  <label className="ml-2 text-sm font-semibold">BSB</label>
                  <input
                    className="flex w-full flex-1 rounded-xl border border-gray-300 px-2 py-3"
                    type="text"
                    placeholder="BSB"
                    maxLength={6}
                    disabled={
                      paymentMethod !== PaymentMethods.PAYTO ||
                      isFetchingPayIDRequest
                    }
                    value={payToBSB.bsb}
                    onChange={(e) => {
                      handleBSBInputs('bsb', e.target.value);
                    }}
                  />
                </div>
                <div className="flex grow flex-col items-start">
                  <label className="ml-2 text-sm font-semibold">
                    Account number
                  </label>
                  <input
                    className="flex w-full flex-1 rounded-xl border border-gray-300 px-2 py-3"
                    type="text"
                    placeholder="Account number"
                    maxLength={100}
                    disabled={
                      paymentMethod !== PaymentMethods.PAYTO ||
                      isFetchingPayIDRequest
                    }
                    value={payToBSB.accountNumber}
                    onChange={(e) => {
                      handleBSBInputs('accountNumber', e.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            {Disclaimer}

            <button
              onClick={() =>
                setPayToMode(payToMode === 'payid' ? 'bsb' : 'payid')
              }
              disabled={
                paymentMethod !== PaymentMethods.PAYTO || isFetchingPayIDRequest
              }
              className="mt-2 bg-transparent text-sm font-medium text-blue-500 underline"
            >
              {payToMode === 'payid'
                ? 'Use a BSB and Account Number instead'
                : 'Use a PayID instead'}
            </button>
          </div>
        )}
      </button>

      <button
        className="flex w-10/12 flex-row items-center justify-start rounded-md bg-neutral-100 px-4 py-3 shadow-md"
        onClick={() => setPaymentMethod(PaymentMethods.EFT)}
      >
        <div
          className={twMerge(
            'mr-3 flex h-3.5 w-3.5 items-center justify-center rounded-full bg-white ring-2 ring-gray-300',
            paymentMethod === PaymentMethods.EFT &&
              'border border-white bg-primary ring-primary'
          )}
        />
        <h2 className="text-lg font-semibold">Pay by EFT</h2>
      </button>

      <button
        className="flex w-10/12 flex-row items-center justify-between rounded-md bg-neutral-100 px-4 py-3 shadow-md"
        onClick={() => setPaymentMethod(PaymentMethods.PAYID)}
      >
        <div className="flex flex-row items-center">
          <div
            className={twMerge(
              'mr-3 flex h-3.5 w-3.5 items-center justify-center rounded-full bg-white ring-2 ring-gray-300',
              paymentMethod === PaymentMethods.PAYID &&
                'border border-white bg-primary ring-primary'
            )}
          />

          <h2 className="text-lg font-semibold">Pay by PayID</h2>
        </div>
        <img src={PayIDLogo} alt="PayTo Logo" className="h-[34px]" />
      </button>

      <div className="mx-6 mb-5 flex w-10/12 flex-col">
        <p className="text-left text-primary">NOTES:</p>
        <p className="text-left text-sm">
          For Anytime Escrow transactions - you will be required to authorise
          final settlement to the seller after you have received your goods or
          services.
          <br />
          <br />
          For Timed Escrow transactions - settlement will be automatic unless
          you lodge a dispute to pause the transaction. If both you and the
          seller agree funds held in escrow can be reversed back to you.
        </p>
      </div>

      <div className="mt-3 w-full sm:mb-10 sm:w-1/2 sm:pr-2">
        <button
          className="btn-main w-full self-center py-2 sm:self-start"
          onClick={handleContinue}
          disabled={isContinueDisabled}
        >
          <div className="flex flex-row items-center justify-center">
            <h2 className="text-xl font-semibold">Continue</h2>
            {isPayIDLookupLoading ? (
              <img
                src={Spinner}
                className="ml-3 h-[30px]"
                alt="Loading spinner"
              />
            ) : (
              <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
            )}
          </div>
        </button>
      </div>

      <div>
        <button className="btn-main" onClick={handleBypassPay}>
          Dev Bypass Payment
        </button>
      </div>

      {/* BSB MOdal */}
      <Modal
        isOpen={showBsbAccountModal}
        onClose={() => setShowBsbAccountModal(false)}
      >
        <Dialog.Title
          as="h3"
          className="text-center text-lg font-medium leading-6 text-primary"
        >
          Transfer Amount to GoESCROW
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <p className="text-center text-sm">
            Trust Account for this Transaction via BSB and Account Number
          </p>

          <div className="flex flex-col items-center justify-center bg-neutral-200 py-3 px-4 text-start">
            <div className="flex flex-col bg-white px-4 py-3 shadow-md">
              <h4 className="font-medium text-primary">EFT</h4>
              <div className="my-2 ml-3 border-l-2 border-l-primary p-2">
                <p className="text-neutral-600">
                  Bank Account BSB:{'  '}
                  <span className="text-primary">
                    {paymentData?.data?.bank?.bsb || ''}
                  </span>
                </p>
                <p className="text-neutral-600">
                  Account Number:{'  '}
                  <span className="text-primary">
                    {paymentData?.data?.bank?.account_number || ''}
                  </span>
                </p>
                <p className="text-neutral-600">
                  Account Name:{'  '}
                  <span className="text-primary">
                    {paymentData?.data?.bank?.account_name || ''}
                  </span>
                </p>
                <p className="font-semibold text-neutral-600">
                  Deposit Amount:{'  '}
                  <span className="font-semibold text-primary">
                    $
                    {parseFloat(dealDetails?.remaining_payment_amount).toFixed(
                      2
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 flex flex-1">
            <button
              onClick={() => setShowBsbAccountModal(false)}
              className="w-full rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* PayID MOdal */}
      <Modal isOpen={showPayIdModal} onClose={() => setShowPayIdModal(false)}>
        <Dialog.Title
          as="h3"
          className="text-center text-lg font-medium leading-6 text-primary"
        >
          Transfer Amount to GoESCROW
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <p className="text-center text-sm">
            Trust Account for this Transaction via PayID
          </p>

          <div className="flex items-center justify-center rounded-lg bg-neutral-200 py-3 px-4">
            <div className="flex flex-col bg-white px-4 py-3 shadow-md">
              <img
                src={PayIDLogo}
                alt="PayTo Logo"
                className="h-[34px] self-start"
              />

              <div className="my-2 ml-3 border-l-2 border-l-primary p-2">
                <p className="text-neutral-600">
                  PayID Email:{'  '}
                  <span className="text-primary">
                    {paymentData?.data?.pay_id?.email || ''}
                  </span>
                </p>
                <p className="font-semibold text-neutral-600">
                  Deposit Amount:{'  '}
                  <span className="font-semibold text-primary">
                    $
                    {parseFloat(dealDetails?.remaining_payment_amount).toFixed(
                      2
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-1">
            <button
              onClick={() => setShowPayIdModal(false)}
              className="w-full rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* PayTO Pending Modal */}
      <Modal
        isOpen={showPayToPending}
        onClose={() => {
          // do nothiing
        }}
        fullscreen
      >
        <Dialog.Title
          as="h3"
          className="text-center text-lg font-medium leading-6 text-primary"
        >
          PayTO
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <div className="flex w-full justify-between">
            <h4 className="font-semibold">One last step in your bank</h4>
            <img src={PayTOLogo} alt="PayTo Logo" className="h-[30px]" />
          </div>

          <div className="my-2 ml-3 flex flex-row items-start gap-3 rounded-l-xl border-l-4 border-l-primary p-2">
            <TbClockExclamation className="flex text-2xl text-primary" />
            <div>
              <p className="flex flex-1 text-left">
                A PayTo agreement has been sent to your online banking.
                <br />
                <br />
                You have 10 minutes to authorise the transaction.
              </p>
              <Timer
                date={payTOActiveTime}
                render={({ formatted, completed }) => {
                  const { minutes, seconds } = formatted;
                  return (
                    <div
                      className={
                        'flex-row items-center text-center font-semibold text-primary'
                      }
                    >
                      <p>
                        <MdTimer className="mr-2 inline-block text-lg" />
                        {completed ? '0:00' : `${minutes}:${seconds}`}
                      </p>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransferPayment;
