import 'react-toastify/dist/ReactToastify.css';
import Composer from 'contexts/Composer';
import QueryContextProvider from 'contexts/QueryContextProvider';
import AppRoutes from 'routers';
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <Composer components={[QueryContextProvider]}>
      <ToastContainer />
      <AppRoutes />
    </Composer>
  );
};

export default App;
