import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AUStates } from './BasicDetails';
import { addDays, format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { DateTimeFormats } from 'utils/parsers';
import { toastError } from 'utils/toast';
import { Dropdown } from 'components/Dropdown';
import FormInput from 'components/FormInput';
import ReactDatePicker from 'react-datepicker';

type Props = {
  onFinish: () => void;
  mode?: 'create' | 'read' | 'update';
  data?: any;
};

const DriverLicenseForm: React.FC<Props> = ({ onFinish, mode, data }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { uploadDriverLicense } = useUserRequests();

  const schema = yup
    .object({
      state_issued: yup
        .string()
        .oneOf(AUStates)
        .defined()
        .required('State required'),
      license_number: yup.string().required('License number required'),
      card_number: yup.string().required('Card number required'),
      expiration_date: yup
        .date()
        .min(addDays(new Date(), 1), 'Expiry date cannot be present or past')
        .required('Expiry date is required'),
      first_name: yup.string().required('First name is required'),
      middle_name: yup.string(),
      last_name: yup.string().required('Family Name required'),
      date_of_birth: yup.date().required('Birth Date required'),
    })
    .required();

  if (data && data.date_of_birth && data.date_of_birth !== null) {
    data.date_of_birth = new Date(data.date_of_birth);
  }

  if (data && data.expiration_date && data.expiration_date !== null) {
    data.expiration_date = new Date(data.expiration_date);
  }

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    ...(data ? { defaultValues: data } : {}),
  });

  const stateValue = watch('state_issued');
  const expiryDateValue = watch('expiration_date');
  const birthDateValue = watch('date_of_birth');

  const onStateSelectOption = (value: any) => {
    setValue('state_issued', value, { shouldValidate: true });
  };

  const handleBirthDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('date_of_birth', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleExpiryDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('expiration_date', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleContinue = useCallback(
    async (formData: any) => {
      setIsSubmitting(true);

      const body = {
        state_issued: formData.state_issued,
        license_number: formData.license_number,
        card_number: formData.card_number,
        expiration_date: format(
          formData.expiration_date,
          DateTimeFormats.FormDate
        ),
        first_name: formData.first_name,
        middle_name: formData.middle_name,
        last_name: formData.last_name,
        date_of_birth: format(formData.date_of_birth, DateTimeFormats.FormDate),
      };

      const res = await uploadDriverLicense(body);

      setIsSubmitting(false);
      if (res.status) {
        onFinish();
      } else {
        toastError();
      }
    },
    [uploadDriverLicense]
  );

  return (
    <div>
      <h2 className="mb-7 text-xl">Driver&apos;s License Information</h2>

      <div className="flex-1 flex-col space-y-3">
        <div>
          <label className="pl-1 text-sm text-secondary">
            State or Territory Issued
          </label>
          <Dropdown
            disabled={mode === 'read'}
            items={AUStates}
            selected={stateValue}
            onChange={onStateSelectOption}
          />
        </div>

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="license_number"
          label="License Number"
          type="text"
          placeholder="License Number"
          errorMessage={errors.license_number?.message as string}
        />

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="card_number"
          label="Card Number"
          type="text"
          placeholder="Card Number"
          errorMessage={errors.card_number?.message as string}
        />

        <div>
          <label className="pl-1 text-sm text-secondary">Expiry Date</label>
          <ReactDatePicker
            disabled={mode === 'read'}
            selected={expiryDateValue}
            onChange={handleExpiryDatePickConfirm}
            dateFormatCalendar="MMMM"
            dateFormat={DateTimeFormats.DisplayDateShort}
            showYearDropdown
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            minDate={new Date()}
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.expiry_date && errors.expiry_date?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.expiry_date.message}
            </span>
          ) : null}
        </div>

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="first_name"
          label="Firs Name"
          type="text"
          placeholder="First Name"
          errorMessage={errors.first_name?.message as string}
        />

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="middle_name"
          label="Middle Name"
          type="text"
          placeholder="Middle Name"
        />

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="last_name"
          label="Last Name"
          type="text"
          placeholder="Last Name"
          errorMessage={errors.last_name?.message as string}
        />

        <div>
          <label className="pl-1 text-sm text-secondary">Date of Birth</label>
          <ReactDatePicker
            disabled={mode === 'read'}
            selected={birthDateValue}
            onChange={handleBirthDatePickConfirm}
            dateFormatCalendar="MMMM"
            dateFormat={DateTimeFormats.DisplayDateShort}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date()}
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.date_of_birth && errors.date_of_birth?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.date_of_birth.message}
            </span>
          ) : null}
        </div>
      </div>

      {mode !== 'read' ? (
        <button
          className="btn-main mt-10 w-full"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(handleContinue)}
        >
          Continue
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DriverLicenseForm;
