import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserRequests } from 'hooks/user-hooks';
import { useForm } from 'react-hook-form';
import { addDays, format } from 'date-fns';
import { DateTimeFormats } from 'utils/parsers';
import { toastError } from 'utils/toast';
import { Dropdown } from 'components/Dropdown';
import FormInput from 'components/FormInput';
import ReactDatePicker from 'react-datepicker';

type Props = {
  onFinish: () => void;
  mode?: 'create' | 'read' | 'update';
  data?: any;
};

const MedicareForm: React.FC<Props> = ({ onFinish, mode, data }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { uploadMedicare } = useUserRequests();

  const schema = yup
    .object({
      card_color: yup
        .string()
        .oneOf(['Green', 'Blue', 'Yellow'])
        .defined()
        .required('Card Color required'),
      document_number: yup.string().required('Medicare number required'),
      individual_reference_number: yup
        .string()
        .required('Individual Reference number required'),
      expiration_date: yup
        .date()
        .min(addDays(new Date(), 1), 'Valid date cannot be present or past')
        .required('Valid date is required'),
    })
    .required();

  if (data && data.expiration_date && data.expiration_date !== null) {
    data.expiration_date = new Date(data.expiration_date);
  }

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    ...(data ? { defaultValues: data } : {}),
  });
  const colorValue = watch('card_color');
  const validDateValue = watch('expiration_date');

  const onColorSelectOption = (value: any) => {
    setValue('card_color', value, { shouldValidate: true });
  };

  const handleDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('expiration_date', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleContinue = useCallback(
    async (formData: any) => {
      setIsSubmitting(true);

      const body = {
        document_number: formData.document_number,
        card_color: formData.card_color,
        individual_reference_number: formData.individual_reference_number,
        expiration_date: format(
          formData.expiration_date,
          DateTimeFormats.FormDate
        ),
      };

      const res = await uploadMedicare(body);

      setIsSubmitting(false);
      if (res.status) {
        onFinish();
      } else {
        toastError();
      }
    },
    [uploadMedicare]
  );

  return (
    <div>
      <h2 className="mb-7 text-xl">Medicare Information</h2>

      <div className="flex-1 flex-col space-y-3">
        <div>
          <label className="pl-1 text-sm text-secondary">Card Colour</label>
          <Dropdown
            disabled={mode === 'read'}
            items={['Green', 'Blue', 'Yellow']}
            selected={colorValue}
            onChange={onColorSelectOption}
          />
          {errors.card_color && errors.card_color?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.card_color.message}
            </span>
          ) : null}
        </div>

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="document_number"
          label="Medicare Number"
          type="text"
          placeholder="Medicare Number"
          errorMessage={errors.document_number?.message as string}
        />

        <FormInput
          control={control}
          disabled={mode === 'read'}
          name="individual_reference_number"
          label="Individual Reference Number"
          type="text"
          placeholder="Individual Reference Number"
          errorMessage={errors.individual_reference_number?.message as string}
        />

        <div>
          <label className="pl-1 text-sm text-secondary">Date of Expiry</label>
          <ReactDatePicker
            disabled={mode === 'read'}
            selected={validDateValue}
            onChange={handleDatePickConfirm}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            minDate={new Date()}
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.expiration_date && errors.expiration_date?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.expiration_date.message}
            </span>
          ) : null}
        </div>
      </div>

      {mode !== 'read' ? (
        <button
          className="btn-main mt-10 w-full"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(handleContinue)}
        >
          Continue
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MedicareForm;
