import { Outlet, useNavigate } from 'react-router-dom';

import logo from 'assets/images/logo-withtext-horizontal.png';
import { useCallback } from 'react';
import { BiLogOutCircle } from 'react-icons/bi';
import { FiArrowLeft } from 'react-icons/fi';
import { useAuthRequests } from 'hooks/auth-hooks';

const Onboarding = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthRequests();

  const handleLogout = useCallback(async () => {
    await signOut();
  }, [signOut, navigate]);

  const handleBack = useCallback(async () => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="flex h-auto min-h-screen flex-1 flex-col items-center bg-offwhiteBG pt-0 pb-10 sm:pt-3 md:pb-5">
      <div className="relative flex w-11/12 flex-1 flex-col items-stretch self-center sm:max-w-[900px]">
        <div className="mb-2 flex w-full flex-row items-center justify-between">
          <img src={logo} alt="GoEscrow Logo" className="flex h-7" />
          {/* <button type="button">
              <BiSearch className="hidden text-2xl text-gray-500 sm:flex" />
            </button>
            <button type="button">
              <BiBell className="hidden text-2xl text-gray-500 sm:flex" />
            </button> */}
          <div className="my-4 flex flex-row items-center gap-3">
            <button
              className="flex flex-row rounded-sm bg-white px-3 py-2 shadow-lg"
              onClick={handleBack}
            >
              <h4 className="flex flex-row items-center gap-2">
                <FiArrowLeft className="text-black" />
                Back
              </h4>
            </button>
            <div className="flex flex-row items-center space-x-5">
              <button
                className="flex flex-row rounded-sm bg-primary px-3 py-2 shadow-md"
                onClick={handleLogout}
              >
                <h4 className="flex flex-row items-center gap-2 text-white">
                  <BiLogOutCircle />
                  Log Out
                </h4>
              </button>
            </div>
          </div>
        </div>
        <div className=" overflow-auto rounded-sm bg-white p-3 pb-10 drop-shadow-xl sm:p-5 sm:pb-20">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
